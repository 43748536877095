/* eslint-disable no-undef */
import { createContext, useState, useEffect, useContext, useRef } from 'react';
import { node, bool } from 'prop-types';
import { setCookie } from 'nookies';

import { getMarket, handleStorage, areEqual, COOKIES, getCookie } from 'utils/helpers';
import { login, getUserData, createAccount } from 'actions/users';
import { IS_CAMPER, IS_NNORMAL } from 'utils/constants/system';
import { fetchZappUserOnGetNnormalUser } from 'actions/nnormal-world';
import { useRouter } from 'next/router';

export const userContext = createContext(undefined);

function routeNeedsOrders(pathname) {
  if (!pathname || IS_CAMPER) return true;
  const pathnamesWithOrders = ['/myaccount/myorders', '/myaccount/myorders/orderdetails'];
  return pathnamesWithOrders.some((path) => pathname.includes(path));
}

const PREVENT_GET_USER_DATA_PAGES = ['Home', 'grid', 'ProductSheet'];

const UserProvider = ({ autoappliedVoucher, children, i18nPage }) => {
  const router = useRouter();
  const storedRefreshToken = handleStorage('getItem', 'refreshToken');
  const [userData, setUserData] = useState(null);
  const [shouldLogin, setShouldLogin] = useState(false);
  const [userLoading, setUserLoading] = useState(true);

  // FIXME: No se usa, cuando hace set también lo mete en storage y lee de ahí
  const [, setSubscribed] = useState(false);
  const [appliedVoucher, setAppliedVoucher] = useState(autoappliedVoucher);
  const [shouldRecalculateVouchers, setShouldRecalculateVouchers] = useState(0);
  const [isGTMReady, setIsGTMReady] = useState(false);
  const [contextFilters, setContextFilters] = useState(null);
  const [activatedPrevouchers, setActivatedPrevouchers] = useState([]);
  const hasCreatedUserFromZapp = useRef(false);

  const increaseShouldRecalculateVouchers = () => {
    setShouldRecalculateVouchers((current) => current + 1);
  };

  const handleActivatePrevoucher = (prevoucher) => {
    if (!activatedPrevouchers.includes(prevoucher)) {
      setActivatedPrevouchers((current) => {
        const newActivated = [...current, prevoucher];
        const activatedInStorage = JSON.parse(handleStorage('getItem', 'activatedPrevouchers') ?? '[]');

        if (!activatedInStorage.includes(prevoucher)) {
          handleStorage('setItem', 'activatedPrevouchers', JSON.stringify([...activatedInStorage, prevoucher]));
        }

        return newActivated;
      });
    }
  };

  const handleGetUserData = async (locale) => {
    try {
      setUserLoading(true);
      const showOrders = routeNeedsOrders(router.pathname);
      const result = await getUserData(locale, showOrders === true ? true : undefined);
      // en zapp nnormal world hace falta crear el usuario en el logueo para evitar que se creen mas de 2 con el mismo email a la vez
      if (IS_NNORMAL) {
        const nwCookie = getCookie(COOKIES.NNORMAL_WORLD_USER);
        if (!nwCookie && result?.account?.kboix === true && !hasCreatedUserFromZapp.current) {
          const fetchedUser = await fetchZappUserOnGetNnormalUser();
          hasCreatedUserFromZapp.current = true;
          if (fetchedUser !== null) {
            setCookie(null, COOKIES.NNORMAL_WORLD_USER, 'true', {
              sameSite: 'strict',
            });
          }
        }
      }
      setUserData(result);
      setShouldLogin(false);
      setUserLoading(false);
    } catch (error) {
      handleStorage('removeItem', 'token');
      handleStorage('removeItem', 'refreshToken');
      handleStorage('removeItem', 'tokenMarket');
      setUserData(null);
      setUserLoading(false);
      setShouldLogin(true);
      throw error;
    }
  };

  const handleLogin = async (user, password, locale) => {
    setUserLoading(true);

    return login(user, password)
      .then(({ token, refreshToken, info }) => {
        const market = getMarket(locale);
        handleStorage('setItem', 'token', token);
        handleStorage('setItem', 'refreshToken', refreshToken);
        handleStorage('setItem', 'tokenMarket', market);
        handleGetUserData(locale);
        return {
          error: false,
          token,
          refreshToken,
          info,
        };
      })
      .catch((e) => {
        setUserLoading(false);
        return { error: true, errorMessage: e };
      });
  };

  const handleCreateAccount = (locale, accountData) =>
    createAccount(locale, accountData)
      .then(() => handleLogin(accountData.email, accountData.password, locale))
      .catch((e) => ({ error: true, errorMessage: e }));

  const logout = () => {
    setUserLoading(true);
    setUserData(null);
    handleStorage('removeItem', 'token');
    handleStorage('removeItem', 'shippingForm');
    handleStorage('removeItem', 'refreshToken');
    setShouldLogin(true);
  };

  const handleGetSubscribed = () => {
    const value = handleStorage('getItem', 'subscribed');
    return value === undefined ? undefined : JSON.parse(value);
  };

  const handleSetSubscribed = (value) => {
    handleStorage('setItem', 'subscribed', value);
    setSubscribed(value);
  };

  useEffect(() => {
    const getUserDataHandler = async () => {
      const { pathname } = window.location;
      const cookiesProfile = pathname.split('/')[1];
      let error = false;

      if (storedRefreshToken) {
        try {
          if (!PREVENT_GET_USER_DATA_PAGES.includes(i18nPage)) {
            await handleGetUserData(cookiesProfile);
          } else {
            setUserData({});
            setShouldLogin(false);
            setUserLoading(false);
          }
        } catch (rsError) {
          error = true;
        }
        // access token -> JWT (userData)

        // refresh token -> JWT ( user, password )
      } else {
        error = true;
      }

      const isMyAccountPath = pathname.includes('/myaccount') && !pathname.includes('/myaccount/myorders/orderdetails');
      const registerPaths = ['/register_pelotas', '/member_register_pelotas'].filter((path) => pathname.includes(path));
      const hasToLogin = isMyAccountPath || registerPaths.length > 0;
      if (error && hasToLogin) {
        setUserLoading(false);
        setShouldLogin(true);
      }
      //  if saved token (localStorage, cookie, etc)
      //    if is alive
      //      autologin
      //    else
      //      autologin or kick?
      //  else
      //    not logged in

      const storageFilters = JSON.parse(handleStorage('getItem', 'contextFilters') ?? '{}');
      const storagePrevouchers = JSON.parse(handleStorage('getItem', 'activatedPrevouchers') ?? '[]');
      setContextFilters(storageFilters);
      setActivatedPrevouchers(storagePrevouchers);
    };

    getUserDataHandler();
  }, []); // mount, check logged in

  const handleContextFilters = (filters) => {
    const storageFilters = JSON.parse(handleStorage('getItem', 'contextFilters') ?? '{}');
    let newFilters = { ...storageFilters };

    if (filters !== null) {
      newFilters = { ...newFilters, ...filters };
    }

    let shouldWriteToStorage = false;

    if (storageFilters !== undefined) {
      if (filters !== null) {
        if (!areEqual(storageFilters, filters)) {
          shouldWriteToStorage = true;
        }
      }
    } else {
      shouldWriteToStorage = true;
    }

    if (filters === null) {
      shouldWriteToStorage = false;
    }

    if (shouldWriteToStorage) {
      handleStorage('setItem', 'contextFilters', JSON.stringify(newFilters));
    }

    setContextFilters(filters);
  };

  useEffect(() => {
    if (userLoading === true) {
      setUserLoading(false);
    }
  }, [userData]);

  useEffect(() => {
    if (appliedVoucher === true) {
      increaseShouldRecalculateVouchers();
    }
  }, [appliedVoucher]);

  return (
    <userContext.Provider
      value={{
        userData,
        login: handleLogin,
        logout,
        getUserData,
        createAccount: handleCreateAccount,
        loading: userLoading,
        getSubscribed: handleGetSubscribed,
        setSubscribed: handleSetSubscribed,
        shouldLogin,
        setAppliedVoucher,
        appliedVoucher,
        shouldRecalculateVouchers,
        increaseShouldRecalculateVouchers,
        isGTMReady,
        setIsGTMReady,
        contextFilters,
        setContextFilters: handleContextFilters,
        activatedPrevouchers,
        handleActivatePrevoucher,
      }}
    >
      {children}
    </userContext.Provider>
  );
};

UserProvider.propTypes = {
  children: node,
  autoappliedVoucher: bool,
};

export function useUser() {
  const context = useContext(userContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}

export default UserProvider;
